import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import FolderIcon from "@material-ui/icons/Folder";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import styled from "styled-components";

import GalleryImage from "./GalleryImage";
import GalleryGrid from "./GalleryGrid";
import Share from "./Share";
import GalleryContainer from "./GalleryContainer";
import DownloadAllButtonContainer from "./DownloadAllButton";
import { Heading } from "./components";
import MaterialButton from "@material-ui/core/Button";
import { getThumbnailUrl, getFullSizeUrl } from "../utils";
import PropTypes from "prop-types";

const getImagesFromList = (list) => {
  return list.filter((entry) => !entry.endsWith("/"));
};

const getFoldersFromList = (list) => {
  return list.filter((entry) => entry.endsWith("/"));
};

const getLinks = async (url) => {
  const result = await fetch(url);
  const html = await result.text();
  const links = html.match(/href="([^"]*")/g);
  return links.map((link) => link.replace('href="', "").replace('"', ""));
};

const isParentFolder = (fullPath, folder) => {
  const pathComponents = fullPath.split("/");
  const parentPath = pathComponents.slice(-2)[0];
  return parentPath === folder.split("/").join("") || folder === "/";
};

const downloadAll = (fullPath, onComplete, onError) => {
  const url =
    "https://download.anneandersen.no/zipFolder.php?path=" + fullPath + "/";
  fetch(url)
    .then(async (response) => {
      const json = await response.json();
      let url = json.downloadUrl;
      let a = document.createElement("a");
      a.href = url;
      a.download = "bilder.zip";
      a.click();
      onComplete();
    })
    .catch(onError);
};

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

function Gallery(props) {
  const { path = "", subPath = "" } = useParams();
  const fullPath = subPath === "" ? path : path + "/" + subPath;
  const [images, setImages] = useState([]);
  const [folders, setFolders] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const isShare = props.share;
  const isRoot = fullPath === "";

  useEffect(() => {
    getLinks("https://bilder.anneandersen.no/" + fullPath + "/").then(
      (links) => {
        const images = getImagesFromList(links);
        const folders = getFoldersFromList(links);
        setImages(images);
        setFolders(folders);
      }
    );
  }, [fullPath]);

  return (
    <GalleryContainer>
      <HeadingContainer>
        <Heading>
          {fullPath.split("_").join(" ").split("/").join(" | ")}
        </Heading>
        {!isShare && subPath === "" && !isRoot && <Share />}
      </HeadingContainer>

      {folders.map((folder) => {
        const isParent = isParentFolder(fullPath, folder);
        if (isParent && isShare) {
          return null;
        }

        const FolderIconComponent = isParent ? ArrowUpwardIcon : FolderIcon;
        return (
          <p key={folder}>
            <MaterialButton
              startIcon={
                <FolderIconComponent fontSize="default" color="primary" />
              }
              color="primary"
              component={RouterLink}
              to={subPath !== "" && isParent ? folder + "s/" : folder}
            >
              {isParent
                ? "Overordnet mappe"
                : folder.split("/").join("").split("_").join(" ")}
            </MaterialButton>
          </p>
        );
      })}
      {images.length > 0 && (
        <DownloadAllButtonContainer>
          <MaterialButton
            startIcon={<CloudDownloadIcon />}
            disabled={isDownloading}
            color="primary"
            variant="contained"
            onClick={() => {
              setIsDownloading(true);
              downloadAll(
                fullPath,
                () => {
                  setIsDownloading(false);
                },
                (error) => {
                  setIsDownloading(false);
                  alert(
                    "Filene var for store til å laste ned fra websiden. Vennligst kontakt Anne Andersen for å få tilsendt alle bildene."
                  );
                }
              );
            }}
          >
            {isDownloading ? "Nedlastingen begynner snart..." : "Last ned alle"}
          </MaterialButton>
        </DownloadAllButtonContainer>
      )}
      <GalleryGrid>
        {images.map((image, index) => {
          return (
            <GalleryImage
              key={image}
              src={getThumbnailUrl(image, fullPath)}
              fullSizeUrl={getFullSizeUrl(image, fullPath)}
            />
          );
        })}
      </GalleryGrid>
    </GalleryContainer>
  );
}

Gallery.propTypes = {
  share: PropTypes.bool,
};
Gallery.defaultProps = { share: false };

export default Gallery;
