import React, { useState, Fragment } from "react";
import ShareIcon from "@material-ui/icons/Share";
import MaterialButton from "@material-ui/core/Button";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styled from "styled-components";
const Container = styled.div``;

const Share = (props) => {
  const [showMessage, setShowMessage] = useState(false);
  return (
    <Container>
      <Fragment>
        <CopyToClipboard
          text={`${window.location}s/`}
          onCopy={() => {
            setShowMessage(true);
            setTimeout(() => setShowMessage(false), 2000);
          }}
        >
          <MaterialButton disabled={showMessage}>
            {showMessage && "Link Kopiert"}
            {!showMessage && <ShareIcon />}
          </MaterialButton>
        </CopyToClipboard>
      </Fragment>
    </Container>
  );
};

export default Share;
