import React from "react";
import Gallery from "./Gallery";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Logo from "./components/Logo";
import Footer from "./components/Footer";
import "./App.css";

const HeaderShare = () => (
  <center>
    <a href="https://www.anneandersen.no">
      <Logo src={require("./assets/logo.png")} />
    </a>
  </center>
);

const Header = () => (
  <center>
    <a href="/">
      <Logo src={require("./assets/logo.png")} />
    </a>
  </center>
);

function App() {
  return (
    <Router className="App">
      <Switch>
        <Route path="/:path+/s/:subPath+">
          <HeaderShare />
          <Gallery />
        </Route>
        <Route path="/:path+/s">
          <HeaderShare />
          <Gallery share />
        </Route>
        <Route path="/:path+">
          <Header />
          <Gallery />
        </Route>
        <Route path="/">
          <Header />
          <Gallery />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
